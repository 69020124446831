import { api, nodeApi, apiCi } from "../../lib/api";
import { success, failure, pending } from "../types";
import createAction, { createApiAction } from "../createAction";
import _ from 'lodash';
import download from '../../assets/js/download';

import getAssessmentsApiLocal from "../../database/api/assessments/getAssessments";
import getAssessmentQuestionsApiLocal from "../../database/api/assessments/getAssessmentQuestions";
import getQuestionsApiLocal from "../../database/api/assessments/getQuestions";
import addAssessmentApiLocal from "../../database/api/assessments/addAssessment";
import addAssessmentQuestionApiLocal from "../../database/api/assessments/addAssessmentQuestion";
import editAssessmentApiLocal from "../../database/api/assessments/editAssessment";
import continueAssessmentApiLocal from "../../database/api/assessments/continueAssessment";
import deleteAssessmentApiLocal from "../../database/api/assessments/deleteAssessment";
import deleteAssessmentQuestionApiLocal from "../../database/api/assessments/deleteAssessmentQuestion";
import getFiltersLv1ApiLocal from "../../database/api/assessments/getFiltersLv1";
import getFiltersLv2ApiLocal from "../../database/api/assessments/getFiltersLv2";
import finishAssessmentApiLocal from "../../database/api/assessments/finishAssessment";
import copyAssessmentApiLocal from "../../database/api/assessments/copyAssessment";
import getFilterLv2CatalogingsApiLocal from "../../database/api/assessments/getFilterLv2Catalogings";
import getAssessmentPreviewApiLocal from "../../database/api/assessments/assessmentPreview";
import removeAssessmentLogoApiLocal from "../../database/api/assessments/removeAssessmentLogo";
import getQrCodeApiLocal from "../../database/api/assessments/getQrCode";
import assessmentFeedbackApiLocal from "../../database/api/assessments/assessmentFeedback";
import assessmentCatalogingApiLocal from "../../database/api/assessments/assessmentCataloging";
import updateUserInfoApiLocal from "../../database/api/auth/updateUserInfo";

import syncAssessmentApiLocal from "../../database/api/assessments/syncAssessment";
import updateSyncAssessmentApiLocal from "../../database/api/assessments/updateSyncAssessment";
import cleanErrorsApiLocal from "../../database/api/assessments/cleanErrors";

import checkLoginSyncApiLocal from "../../database/api/auth/checkLoginSync";

import { FINISH_ASSESSMENT_STUDENT } from "../assessmentStudent";

// action types
export const GET_ASSESSMENTS = "assessments/GET_ASSESSMENTS";
export const GET_ASSESSMENTS_SM = "assessments/GET_ASSESSMENTS_SM";
export const GET_ASSESSMENTS_SHARE = "assessments/GET_ASSESSMENTS_SHARE";
export const ADD_ASSESSMENT = "assessments/ADD_ASSESSMENT";
export const EDIT_ASSESSMENT = "assessments/EDIT_ASSESSMENT";
export const CONTINUE_ASSESSMENT = "assessments/CONTINUE_ASSESSMENT";
export const COPY_ASSESSMENT = "assessments/COPY_ASSESSMENT";
export const REMOVE_ASSESSMENT = "assessments/REMOVE_ASSESSMENT";
export const FINISH_ASSESSMENT = "assessments/FINISH_ASSESSMENT";
export const FINISH_ASSESSMENT_ONLINE = "assessments/FINISH_ASSESSMENT_ONLINE";
export const SELECT_ASSESSMENT = "assessments/SELECT_ASSESSMENT";
export const GET_QUESTIONS = "assessments/GET_QUESTIONS";
export const GET_ASSESSMENT_QUESTIONS = "assessments/GET_ASSESSMENT_QUESTIONS";
export const ADD_ASSESSMENT_QUESTION = "assessments/ADD_ASSESSMENT_QUESTION";
export const DELETE_ASSESSMENT_QUESTION = "assessments/DELETE_ASSESSMENT_QUESTION";
export const GET_ASSESSMENT_CATALOGING = "assessments/GET_ASSESSMENT_CATALOGING";
export const GET_FILTERS_LVL1 = "assessments/GET_FILTERS_LVL1";
export const GET_FILTERS_LVL2 = "assessments/GET_FILTERS_LVL2";
export const GET_FILTER_LV2_CATALOGINGS = "assessments/GET_FILTER_LV2_CATALOGINGS";
export const REMOVE_ALERT = "assessments/REMOVE_ALERT";
export const GET_ASSESSMENTS_PREVIEW = "assessments/GET_ASSESSMENTS_PREVIEW";
export const GET_QR_CODE = "assessments/GET_QR_CODE";
export const FINISH_SCHEDULED_ASSESSMENTS = "assessments/FINISH_SCHEDULED_ASSESSMENTS";
export const ASSESSMENT_FEEDBACK = "assessments/ASSESSMENT_FEEDBACK";
export const GET_STUDENT_ASSESSMENTS = "assessments/GET_STUDENT_ASSESSMENTS";
export const STUDENT_ASSESSMENT_REPORT = "assessments/STUDENT_ASSESSMENT_REPORT";
export const REMOVE_ASSESSMENT_LOGO = "assessments/REMOVE_ASSESSMENT_LOGO";
export const CHECK_EMAIL = "simplifica-offline/CHECK_EMAIL";
export const REGISTER_USER_SYNC = "simplifica-offline/REGISTER_USER_SYNC";
export const LOGIN_USER_SYNC = "simplifica-offline/LOGIN_USER_SYNC";
export const UPDATE_USER_INFO = "simplifica-offline/UPDATE_USER_INFO";
export const SYNC_ASSESSMENT_OFFLINE = "assessments/SYNC_ASSESSMENT_OFFLINE";
export const SYNC_ASSESSMENT_ONLINE = "assessments/SYNC_ASSESSMENT_ONLINE";
export const UPDATE_SYNC_ASSESSMENT = "simplifica-offline/UPDATE_SYNC_ASSESSMENT";
export const CHECK_LOGIN_SYNC = "simplifica-offline/CHECK_LOGIN_SYNC";
export const CLEAN_ERRORS = "simplifica-offline/CLEAN_ERRORS";
export const VIEW_C_MATERIAL_FILE = "assessments/VIEW_C_MATERIAL_FILE";
export const UPDATE_ASSESSMENT_TYPE = "assessments/UPDATE_ASSESSMENT_TYPE";
// export const GET_FILTERS_METADATA = "assessments/GET_FILTERS_METADATA";
export const REPAIR_ASSESSMENT_QUESTIONS_POSITIONS = "assessments/REPAIR_ASSESSMENT_QUESTIONS_POSITIONS";
export const CHECK_SHARED_QUESTIONS = "assessments/CHECK_SHARED_QUESTIONS";

export const GET_QUESTIONS_YEARS = "assessments/GET_QUESTIONS_YEARS";

export const GET_COMPLEMENTARY_MATERIAL = "assessments/GET_COMPLEMENTARY_MATERIAL";

// actions
export const getAssessments = () =>
  createApiAction(
    GET_ASSESSMENTS,
    () => getAssessmentsApiLocal(),
    () => api.get("/assessments/getAssessments.php"),
  );

export const getAssessmentsSM = () =>
  createApiAction(
    GET_ASSESSMENTS_SM,
    () => api.get("/assessments/getAssessmentsSM.php"),
  );
  
export const getAssessmentsShare = () =>
  createApiAction(
    GET_ASSESSMENTS_SHARE,
    () => getAssessmentsApiLocal(),
    () => api.get("/assessments/getAssessmentsShare.php"),
  );

export const addAssessment = params =>
  createApiAction(
    ADD_ASSESSMENT,
    () => addAssessmentApiLocal(params),
    () => api.post("/assessments/addAssessment.php", params),
  );

export const editAssessment = params =>
  createApiAction(
    EDIT_ASSESSMENT,
    () => editAssessmentApiLocal(params),
    () => api.post("/assessments/editAssessment.php", params),
  );

export const continueAssessment = params =>
  createApiAction(
    CONTINUE_ASSESSMENT,
    () => continueAssessmentApiLocal(params),
    () => api.post("/assessments/continueAssessment.php", params),
  );

export const copyAssessment = params =>
  createApiAction(
    COPY_ASSESSMENT,
    () => copyAssessmentApiLocal(params),
    () => api.post("/assessments/copyAssessment.php", params),
  );

export const removeAssessment = idAssessment =>
  createApiAction(
    REMOVE_ASSESSMENT,
    () => deleteAssessmentApiLocal(idAssessment),
    () => api.post("/assessments/deleteAssessment.php", idAssessment),
  );

export const finishAssessment = params =>
  createApiAction(
    FINISH_ASSESSMENT,
    () => finishAssessmentApiLocal(params),
    () => api.post("/assessments/finishAssessment.php", params),
  );

export const finishAssessmentOnline = params =>
  createAction(FINISH_ASSESSMENT_ONLINE, api.post("/assessments/finishAssessment.php", params));

export const selectAssessment = payload => dispatch =>
  dispatch({ type: success(SELECT_ASSESSMENT), payload });

export const getQuestions = params =>
  createApiAction(
    GET_QUESTIONS,
    () => getQuestionsApiLocal(params),
    () => api.post("/assessments/getQuestions.php", params),
  );

export const checkSharedQuestions = params =>
  createApiAction(
    CHECK_SHARED_QUESTIONS,
    () => api.post("/assessments/getQuestions.php", params),
  );

export const getAssessmentQuestions = assessmentQuestions =>
  createApiAction(
    GET_ASSESSMENT_QUESTIONS,
    () => getAssessmentQuestionsApiLocal(assessmentQuestions),
    () => api.post("/assessments/getAssessmentQuestions.php", assessmentQuestions),
  );

export const addAssessmentQuestion = params =>
  createApiAction(
    ADD_ASSESSMENT_QUESTION,
    () => addAssessmentQuestionApiLocal(params),
    () => api.post("/assessments/addAssessmentQuestion.php", params),
  );

export const deleteAssessmentQuestions = params =>
  createApiAction(
    DELETE_ASSESSMENT_QUESTION,
    () => deleteAssessmentQuestionApiLocal(params),
    () => api.post("/assessments/deleteAssessmentQuestions.php", params),
  );

export const getAssessmentCataloging = params =>
  createApiAction(
    GET_ASSESSMENT_CATALOGING,
    () => assessmentCatalogingApiLocal(params),
    // () => api.post("/assessments/assessmentCataloging.php", params),
    () => api.post("/assessments/assessmentCatalogingNew.php", params),
  );

export const getFiltersLv1 = () =>
  createApiAction(
    GET_FILTERS_LVL1,
    () => getFiltersLv1ApiLocal(),
    () => api.post("/assessments/getFiltersLv1.php"),
  );

export const getFiltersLv2 = params =>
  createApiAction(
    GET_FILTERS_LVL2,
    () => getFiltersLv2ApiLocal(params),
    () => api.post("/assessments/getFiltersLv2.php", params),
  );

export const getFilterLv2Catalogings = params =>
  createApiAction(
    GET_FILTER_LV2_CATALOGINGS,
    () => getFilterLv2CatalogingsApiLocal(params),
    () => api.post("/assessments/getFilterLv2Catalogings.php", params),
  );

export const removeAlert = payload => dispatch =>
  dispatch({ type: success(REMOVE_ALERT), payload });

export const getAssessmentsPreview = params =>
  createApiAction(
    GET_ASSESSMENTS_PREVIEW,
    () => getAssessmentPreviewApiLocal(params),
    () => api.post("/assessments/assessmentPreview.php", params),
  );

export const getQrCode = payload =>
  createApiAction(
    GET_QR_CODE,
    () => getQrCodeApiLocal(payload),
    () => api.post("/assessments/getQrCode.php", payload),
  );

export const finishScheduledAssessments = payload =>
  createAction(
    FINISH_SCHEDULED_ASSESSMENTS,
    api.post("/assessments/finishScheduledAssessments.php", payload),
  );

export const assessmentFeedback = params =>
  createApiAction(
    ASSESSMENT_FEEDBACK,
    () => assessmentFeedbackApiLocal(params),
    () => api.post("/assessments/assessmentFeedback.php", params),
  );

export const getStudentAssessments = () =>
  createAction(GET_STUDENT_ASSESSMENTS, api.get("/assessments/getStudentAssessments.php"));

export const getStudentAssessmentReport = assessmentReport =>
  createAction(
    STUDENT_ASSESSMENT_REPORT,
    api.post("/assessments/getStudentAssessmentReport.php", assessmentReport),
  );

export const removeAssessmentLogo = params =>
  createApiAction(
    REMOVE_ASSESSMENT_LOGO,
    () => removeAssessmentLogoApiLocal(params),
    () => api.post("/assessments/removeAssessmentLogo.php", params),
  );

export const checkEmail = email =>
  createAction(CHECK_EMAIL, api.post("/simplifica-offline/data-sync/checkEmail.php", email));

export const registerSync = params =>
  createAction(
    REGISTER_USER_SYNC,
    api.post("/simplifica-offline/data-sync/registerUser.php", params),
  );

export const loginSync = payload => createAction(LOGIN_USER_SYNC, apiCi.post("/login", payload));

export const updateUserInfo = params =>
  createApiAction(UPDATE_USER_INFO, () => updateUserInfoApiLocal(params));

export const syncAssessmentOffline = params =>
  createApiAction(SYNC_ASSESSMENT_OFFLINE, () => syncAssessmentApiLocal(params));

export const syncAssessmentOnline = params =>
  createAction(
    SYNC_ASSESSMENT_ONLINE,
    api.post("/simplifica-offline/data-sync/syncAssessment.php", params),
  );

export const updateSyncAssessment = params =>
  createApiAction(UPDATE_SYNC_ASSESSMENT, () => updateSyncAssessmentApiLocal(params));

export const checkLoginSync = () =>
  createApiAction(CHECK_LOGIN_SYNC, () => checkLoginSyncApiLocal());

export const cleanErrors = () =>
  createApiAction(CLEAN_ERRORS, () => cleanErrorsApiLocal(), () => cleanErrorsApiLocal());

export const viewCMaterialFile = params =>
  createAction(VIEW_C_MATERIAL_FILE, api.post("/assessments/viewCMaterialFile.php", params), {params});

export const updateAssessmentType = params =>
  createAction(UPDATE_ASSESSMENT_TYPE, api.post("/assessments/updateAssessmentType.php", params), {params});

// export const getFiltersMetadata = () =>
//   createAction(GET_FILTERS_METADATA, api.post("/assessments/getFiltersMetadata.php"));

export const repairAssessmentQuestionsPositions = params =>
  createAction(REPAIR_ASSESSMENT_QUESTIONS_POSITIONS, api.post("/assessments/repairAssessmentQuestionsPositions.php", params));

export const getQuestionsYears = params =>
  createApiAction(
    GET_QUESTIONS_YEARS,
    () => api.post("/assessments/getQuestionsYears.php", params),
  );

export const getComplementaryMaterial = params =>
  createApiAction(
    GET_COMPLEMENTARY_MATERIAL,
    () => api.post("/assessments/getComplementaryMaterial.php", params),
  );

const initialState = {
  assessments: [],
  assessmentsSM: [],
  assessmentsShare: [],
  assessmentReport: "",
  assessmentPreview: null,
  selectedAssessment: null,
  assessmentCataloging: null,
  alerts: [],
  qrCode: null,
  assessmentQuestions: [],
  questions: [],
  sharedQuestions: [],
  idAssessment: null,
  filtersLvl1: [],
  filtersLvl2: [],
  assessmentContinue: null,
  assessmentQuestionStatus: 200,
  filterLv2Catalogings: [],
  feedbackAssessment: {},
  hasEmail: false,
  hasEmailChecked: false,
  hasConnection: true,
  userOnline: null,
  tokenOnline: null,
  emailRegister: "",
  errCode: null,
  errorMessage: "",
  registerSyncSucess: false,
  successLoginSync: false,
  successUpdateSyncAssessment: false,
  syncAssessmentOfflineData: [],
  syncAssessmentOnlineData: [],
  checkLoginSyncData: false,
  cMaterialFile:"",
  filtersMetadata: [],
  informationMetadata: [],
  repaired: false,
  questionsYears: [],
  materials: [],
  loading: false,
  newIdAssessment: null,
};

// reducer
const reducer = (state = initialState, action, params) => {
  switch (action.type) {
    case pending(GET_ASSESSMENTS):
      return {
        ...state,
        loading: true,
      };
    case success(GET_ASSESSMENTS):
      return {
        ...state,
        assessments: action.payload.content,
        loading: initialState.loading,
      };

    case success(GET_ASSESSMENTS_SM):
      return {
        ...state,
        assessmentsSM: action.payload.content,
      };

    case success(GET_ASSESSMENTS_SHARE):
      return {
        ...state,
        assessmentsShare: action.payload.content,
      };
    case pending(ADD_ASSESSMENT):
      return {
        ...state,
        idAssessment: initialState.idAssessment,
      };
    case success(ADD_ASSESSMENT):
      return { ...state, idAssessment: action.payload.content };
    case failure(ADD_ASSESSMENT):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case success(EDIT_ASSESSMENT):
    case success(CONTINUE_ASSESSMENT):
      return { ...state, assessmentContinue: action.payload.content };
    case success(REMOVE_ASSESSMENT):
      return { ...state };
    case failure(REMOVE_ASSESSMENT):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case success(FINISH_ASSESSMENT):
      return { ...state };
    case success(FINISH_ASSESSMENT_ONLINE):
      return { ...state };
    case success(SELECT_ASSESSMENT):
      return { ...state, selectedAssessment: action.payload };
    case pending(GET_QUESTIONS):
      return {
        ...state,
        questions: initialState.questions,
        filtersMetadata: initialState.filtersMetadata,
        informationMetadata: initialState.informationMetadata
      };
    case success(GET_QUESTIONS):
      return {
        ...state,
        questions: action.payload,
        filtersMetadata: action.payload.filtersMetadata,
        // informationMetadata: action.payload.informationMetadata
      };
    case failure(GET_QUESTIONS):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case pending(CHECK_SHARED_QUESTIONS):
      return {
        ...state,
        sharedQuestions: initialState.sharedQuestions,
      };
    case success(CHECK_SHARED_QUESTIONS):
      return {
        ...state,
        sharedQuestions: action.payload.content.questions,
      };
    case failure(CHECK_SHARED_QUESTIONS):
      return {
        ...state,
        sharedQuestions: initialState.sharedQuestions,
      };
    case pending(GET_ASSESSMENT_QUESTIONS):
      return {
        ...state,
        assessmentQuestions: initialState.assessmentQuestions,
      };
    case success(GET_ASSESSMENT_QUESTIONS):
      return {
        ...state,
        assessmentQuestions: action.payload.content.questions,
      };
    case failure(GET_ASSESSMENT_QUESTIONS):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case success(ADD_ASSESSMENT_QUESTION):
      return {
        ...state,
        assessmentQuestionStatus: initialState.assessmentQuestionStatus,
      };
    case success(DELETE_ASSESSMENT_QUESTION):
      return {
        ...state,
        assessmentQuestionStatus: initialState.assessmentQuestionStatus,
      };
    case pending(GET_ASSESSMENT_CATALOGING):
      return {
        ...state,
        assessmentCataloging: initialState.assessmentCataloging,
      };
    case success(GET_ASSESSMENT_CATALOGING):
      return { ...state, assessmentCataloging: action.payload.content };
    case pending(GET_FILTERS_LVL1):
      return {
        ...state,
        filtersLvl1: initialState.filtersLvl1,
      };
    case success(GET_FILTERS_LVL1):
      return { ...state, filtersLvl1: action.payload.content };
    case failure(GET_FILTERS_LVL1):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case pending(GET_FILTERS_LVL2):
      return {
        ...state,
        filtersLvl2: initialState.filtersLvl2,
      };
    case success(GET_FILTERS_LVL2):
      return { ...state, filtersLvl2: action.payload.content };
    case failure(GET_FILTERS_LVL2):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case pending(GET_FILTER_LV2_CATALOGINGS):
      return {
        ...state,
        filterLv2Catalogings: initialState.filterLv2Catalogings,
      };
    case success(GET_FILTER_LV2_CATALOGINGS):
      return { ...state, filterLv2Catalogings: action.payload.content };
    case failure(GET_FILTER_LV2_CATALOGINGS):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case success(REMOVE_ALERT):
      return {
        ...state,
        alerts: state.alerts.filter(a => a.id !== action.payload.id),
      };
    case success(GET_ASSESSMENTS_PREVIEW):
      return { ...state, assessmentPreview: action.payload.content };
    case success(GET_QR_CODE):
      return { ...state, qrCode: action.payload.content };
    case success(FINISH_ASSESSMENT_STUDENT):
      return { ...initialState };
    // case pending(ASSESSMENT_FEEDBACK):
    //   return {
    //     ...state,
    //     feedbackAssessment: initialState.feedbackAssessment,
    //   };
    case success(ASSESSMENT_FEEDBACK):
      return { ...state, feedbackAssessment: action.payload.content };
    case failure(ASSESSMENT_FEEDBACK):
      return {
        ...state,
        alerts: state.alerts.concat([
          {
            type: "error",
            code: action.payload.errCode,
            id: state.alerts.length,
          },
        ]),
      };
    case success(GET_STUDENT_ASSESSMENTS):
      return { ...state, assessments: action.payload.content };
    case pending(STUDENT_ASSESSMENT_REPORT):
      return {
        ...state,
        assessmentReport: initialState.assessmentReport,
      };
    case success(STUDENT_ASSESSMENT_REPORT):
      return { ...state, assessmentReport: action.payload.content };
    case success(REMOVE_ASSESSMENT_LOGO):
    case pending(SYNC_ASSESSMENT_OFFLINE):
      return {
        ...state,
        successUpdateSyncAssessment: initialState.successUpdateSyncAssessment,
        syncAssessmentOfflineData: initialState.syncAssessmentOfflineData,
        syncAssessmentOnlineData: initialState.syncAssessmentOnlineData,
      };
    case success(SYNC_ASSESSMENT_OFFLINE):
      return {
        ...state,
        syncAssessmentOfflineData: action.payload.content,
      };
    case success(SYNC_ASSESSMENT_ONLINE):
      return {
        ...state,
        syncAssessmentOnlineData: action.payload.content,
      };
    case pending(CHECK_EMAIL):
      return {
        ...state,
        hasEmail: initialState.hasEmail,
        hasConnection: initialState.hasConnection,
        errCode: initialState.errCode,
        errorMessage: initialState.errorMessage,
      };
    case success(CHECK_EMAIL):
      return { ...state, hasEmail: action.payload.hasEmail, hasEmailChecked: true };
    case failure(CHECK_EMAIL):
      return { ...state, hasEmail: initialState.hasEmail, hasConnection: false, errCode: 500 };
    case success(REGISTER_USER_SYNC):
      if (action.payload.error) {
        return {
          ...state,
          hasEmail: true,
          emailRegister: action.payload.email,
          errCode: action.payload.errCode,
          errorMessage: action.payload.error,
        };
      } else {
        return {
          ...state,
          hasEmail: true,
          emailRegister: action.payload.email,
          registerSyncSuccess: true,
        };
      }
    case pending(LOGIN_USER_SYNC):
      return {
        ...state,
        errCode: initialState.errCode,
        errorMessage: initialState.errorMessage,
      };
    case success(LOGIN_USER_SYNC):
      api.defaults.headers.common["X-Auth-Token"] = action.payload.token;
      return { ...state, userOnline: action.payload.user, tokenOnline: action.payload.token };
    case failure(LOGIN_USER_SYNC):
      // console.log("ERRO NO LOGIN: ", action.payload);
      // return { ...state, error: action.payload };
      return {
        ...state,
        errCode: action.payload.error.code,
        errorMessage: action.payload.error.message,
      };
    case success(UPDATE_USER_INFO):
      return { ...state, successLoginSync: true };
    case success(UPDATE_SYNC_ASSESSMENT):
      return { ...state, successUpdateSyncAssessment: true };
    case success(CHECK_LOGIN_SYNC):
      return { ...state, checkLoginSyncData: action.payload.content };
    case success(CLEAN_ERRORS):
      return {
        ...state,
        errCode: initialState.errCode,
        errorMessage: initialState.errorMessage,
        registerSyncSuccess: initialState.registerSyncSuccess,
      };
    case success(VIEW_C_MATERIAL_FILE):
        const fileName = _.first(action.params.fileName.split('.'));
        const fileType = _.last(action.params.fileName.split('.'));
        const base64 = action.payload;
      
        const file = `data:application/${fileType};base64,${base64}`;
        download(file, `${fileName}.${fileType}`, `file/${fileType}`);
      return { ...state };

    // case pending(GET_FILTERS_METADATA):
    //   return {
    //     ...state,
    //     filtersMetadata: initialState.filtersMetadata,
    //   };
    // case success(GET_FILTERS_METADATA):
    //   return {
    //     ...state,
    //     filtersMetadata: action.payload.content,
    //     informationMetadata: action.payload.information
    //   };
    case success(REPAIR_ASSESSMENT_QUESTIONS_POSITIONS):
      return {
        ...state,
        repaired: true
      };

    case pending(GET_QUESTIONS_YEARS):
      return {
        ...state,
        questionsYears: initialState.questionsYears,
      };
    case success(GET_QUESTIONS_YEARS):
      return { ...state, questionsYears: action.payload.content };

    case pending(GET_COMPLEMENTARY_MATERIAL):
      return { 
        ...state, 
        materials: initialState.materials,
        loading: true,
      };
    case success(GET_COMPLEMENTARY_MATERIAL):
      return {
        ...state,
        materials: action.payload.content,
        loading: false,
      };

    case pending(COPY_ASSESSMENT):
      return { 
        ...state, 
        newIdAssessment: initialState.newIdAssessment,
        loading: true,
      };
    case success(COPY_ASSESSMENT):
      return {
        ...state,
        newIdAssessment: action.payload.newIdAssessment,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
